<template>
  <div class="D1">
    <div class="banner">
      <div class="title">D1</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <div class="Display">
      <div class="title">Display</div>
      <div class="topText">15.6" high-definition screen</div>
      <div class="bottomText">10 point capacitive screen</div>
      <div class="topLine">
        <img src="../../../assets/D1配图/上.png" alt="" />
      </div>
      <div class="bottomLine">
        <img src="../../../assets/D1配图/下.png" alt="" />
      </div>
    </div>
    <div class="metalMaterial">
      <div class="w">
        <div class="title">Metal Material</div>
        <div class="img">
          <img src="../../../assets/D1配图/第三页.png" alt="" />
        </div>
      </div>
    </div>
    <div class="slimDesign">
      <div class="w">
        <div class="title">Slim design</div>
        <div class="img">
          <img src="../../../assets/D1配图/第四页.png" alt="" />
        </div>
      </div>
    </div>
    <div class="D1con">
      <div class="w">
        <div class="titleBox">
          <div class="title">D1</div>
        </div>
        <div class="itemBox">
          <div class="left">
            <div class="itemA">
              <div class="title">OS</div>
              <div class="text">Android 11</div>
            </div>
            <div class="itemB">
              <div class="title">Memory</div>
              <div class="text">RAM 2GB (4GB for optinal)</div>
              <div class="text">ROM 16GB (32GB for optinal)</div>
            </div>
            <div class="itemB">
              <div class="title">CPU</div>
              <div class="text">Rock Chips RK3566</div>
              <div class="text">ARM Cortex-A55</div>
              <div class="text">Quad-Core, 1.8GHz</div>
            </div>
            <div class="itemB">
              <div class="title">Display</div>
              <div class="text">10 point capacitive screen</div>
              <div class="text">15.6" high-definition screen</div>
            </div>
            <div class="itemA">
              <div class="title">IO Ports</div>
              <div class="text">DC12V * 1</div>
              <div class="text">RJ9 * 1</div>
              <div class="text">USB2.0 * 4</div>
              <div class="text">LAN * 1</div>
            </div>
            <div class="itemB">
              <div class="title">Wireless Interface</div>
              <div class="text">Wi-Fi: 802.11b/g/n</div>
              <div class="text">Bluetooth: 4.0</div>
              <div class="text">4G: Optional</div>
            </div>
            <div class="itemB">
              <div class="title">Camera</div>
              <div class="text">Optional</div>
            </div>
            <div class="itemB">
              <div class="title">Color</div>
              <div class="text">Black</div>
            </div>
          </div>
          <div class="right">
            <div class="bigTitle">Dimensions (L*W*H)</div>
            <div class="smallTitle">391mm*195mm*330mm</div>
            <div class="img">
              <img src="../../../assets/D1配图/分组 2.png" alt="" />
            </div>
            <div class="left text">330mm</div>
            <div class="top text">391mm</div>
            <div class="bottom text">195mm</div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">D1</div>
              <div class="text">PDF(962KB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/D1.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">D1 User Manual</div>
              <div class="text">PDF(2.26MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/D1_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.D1 {
  .banner {
    padding: 53px;
    height: 1000px;
    background: url('../../../assets/D1配图/首页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 86.88px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 60px;
      font-weight: 700;
    }
    .osDMS {
      margin-top: 28px;
      padding: 0px 228px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 363.12px;
        height: 73.4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .Display {
    position: relative;
    height: 1080px;
    background: url('../../../assets/D1配图/第二页.png') no-repeat center;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      position: absolute;
      color: rgba(0, 92, 255, 1);
      font-size: 48px;
      font-weight: 700;
      height: 46px;
      line-height: 25px;
      bottom: 840px;
      left: 441px;
    }
    .topText {
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      line-height: 35px;
      height: 46px;
      position: absolute;
      bottom: 779px;
      left: 441px;
    }
    .bottomText {
      color: rgba(255, 255, 255, 1);
      line-height: 35px;
      font-size: 24px;
      height: 46px;
      position: absolute;
      bottom: 230px;
      left: 441px;
    }
    .topLine {
      position: absolute;
      top: 310px;
      left: 484px;
      width: 615px;
      height: 217px;
    }
    .bottomLine {
      position: absolute;
      bottom: 291px;
      left: 484px;
      width: 615px;
      height: 217px;
    }
  }
  .metalMaterial {
    background: rgba(0, 0, 0, 1);
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 115px 0px 90px 0px;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .slimDesign {
    background: rgba(0, 0, 0, 1);
    padding-bottom: 220px;
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 246px 0px 90px 0px;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .D1con {
    background: rgba(0, 0, 0, 1);
    padding: 0px 11px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 95px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      display: flex;
      justify-content: space-between;
      margin-top: 21px;
      .left {
        width: 890.22px;
        height: 140px;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        width: 890.22;
        height: 140px;
        .itemA {
          margin-top: 23px;
          padding: 19px 0px 0px 26px;
          width: 170px;
          height: 140px;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
        }
        .itemB {
          margin-top: 23px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
        }
      }
      .right {
        position: relative;
        padding: 19px 0px 0px 26px;
        margin-top: 23px;
        width: 267px;
        height: 304px;
        opacity: 1;
        border-radius: 15.35px;
        background: rgba(0, 92, 255, 0.5);
        .bigTitle {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 2px;
        }
        .smallTitle {
          font-size: 12px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
        }
        .img {
          position: absolute;
          left: 53.78px;
          top: 116px;
          width: 165px;
          height: 154px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          color: rgba(255, 255, 255, 1);
          font-size: 8px;
          font-weight: 500;
          line-height: 30px;
        }
        .top {
          position: absolute;
          top: 93px;
          left: 122.78px;
        }
        .left {
          width: fit-content;
          height: fit-content;
          position: absolute;
          top: 174px;
          left: 19.98px;
        }
        .bottom {
          position: absolute;
          bottom: 13px;
          left: 121.78px;
        }
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
